/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 public/models/Office.glb
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'

export function Office(props) {
  const { nodes, materials } = useGLTF('models/Office.glb')
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Cube002.geometry} material={materials['Material.007']} position={[1.2, 0.34, 1.28]} scale={[0.96, 0.03, 0.36]} />
      <mesh geometry={nodes.Cube015.geometry} material={materials.KTOUBA} position={[0.81, 0.38, 1.53]} scale={[0.16, 0.22, 0.33]} />
      <group position={[1.93, 0, 0.77]} scale={0.01}>
        <mesh geometry={nodes['10239_Office_Chair_v1_L3_1'].geometry} material={materials['Material.005']} />
        <mesh geometry={nodes['10239_Office_Chair_v1_L3_2'].geometry} material={materials.INOX} />
      </group>
      <group position={[1.96, 0.79, 1.42]} scale={[0.16, 0.15, 0.15]}>
        <mesh geometry={nodes.Cube009.geometry} material={materials['Material.006']} />
        <mesh geometry={nodes.Cube009_1.geometry} material={materials['Material.012']} />
      </group>
      <group position={[2.57, 0.81, 1.39]} rotation={[0, 0.7, 0]} scale={[.5, .5, .5]}>
        <mesh geometry={nodes.Cylinder010.geometry} material={materials['Material.008']} />
        <mesh geometry={nodes.Cylinder010_1.geometry} material={materials['BYED BRIO']} />
      </group>
      <mesh geometry={nodes.Sphere001.geometry} material={materials['Material.001']} position={[1.24, 1.125, 1.47]} rotation={[0, 1, 0]} scale={[0.2, 0.1, 0.16]} />
    </group>
  )
}

useGLTF.preload('models/Office.glb')
